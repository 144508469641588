<template>
  <section id="producto-editar">
    <b-col cols="14">
      <b-card>
        <div>
          <!-- <div id="loading-bg" v-if="loading">
            <div class="loading">
              <div class="effect-1 effects"></div>
              <div class="effect-2 effects"></div>
              <div class="effect-3 effects"></div>
            </div>
          </div> -->
          <!-- Header: Personal Info -->
          <div class="d-flex">
            <h4 class="my-1">Editar producto</h4>
          </div>

          <!-- Form: Personal Info Form -->
          <b-form class="mt-1">
            <b-row>
              <!-- Field: Nombre -->
              <b-col cols="12" md="6" lg="6">
                <b-form-group label="1. Nombre *" label-for="nombre">
                  <b-form-input
                    id="nombre"
                    v-model="nombre"
                    :state="aceptadoNombre"
                  />
                </b-form-group>
              </b-col>
              <!-- Field: Pasarelas -->
              <b-col cols="12" md="6" lg="6">
                <b-form-group label="2. Pasarelas *" label-for="pasarelas">
                  <v-select
                    id="selectProductos"
                    v-model="pasarelasProducto"
                    :dir="dir"
                    multiple
                    label="title"
                    :options="optionPasarelas"
                    :state="aceptadoPasarela"
                    @input="onChangePasarela"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Servicios -->
              <b-col cols="12" md="6" lg="6">
                <b-form-group label="3. Medios de Pago *" label-for="medios">
                  <v-select
                    id="selectProductos"
                    v-model="medios"
                    :dir="dir"
                    multiple
                    label="title"
                    :options="getMediosPago(pasarelasProducto)"
                    :state="aceptadoMedios"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Servicios -->
              <b-col cols="12" md="6" lg="6">
                <b-form-group label="4. Servicios *" label-for="servicios">
                  <v-select
                    id="selectProductos"
                    v-model="servicios"
                    :dir="dir"
                    multiple
                    label="title"
                    :options="optionServicios"
                    :state="aceptadoServicios"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Codigo -->
              <b-col cols="12" md="6" lg="6">
                <b-form-group
                  label="5. Dirección de Códigos de descuento (opc.)"
                  label-for="codigo"
                >
                  <b-form-input id="codigo" v-model="codigoPage" />
                </b-form-group>
              </b-col>

              <!-- Field: País -->
              <!-- <b-col cols="12" md="6" lg="6">
                <b-form-group
                  label="6. País *"
                  label-for="pais"
                  :state="aceptadoPais"
                >
                  <v-select
                    id="selectProductos"
                    v-model="paises"
                    :dir="dir"
                    multiple
                    label="title"
                    :options="optionPaises"
                    :state="aceptadoPais"
                  />
                </b-form-group>
              </b-col> -->

              <!-- Field: Moneda -->
              <b-col cols="12" md="6" lg="6">
                <b-form-group label="6. Moneda *" label-for="Moneda">
                  <v-select
                    id="selectMoneda"
                    v-model="monedaBase"
                    value="id"
                    :dir="dir"
                    label="name"
                    :options="getMonedas(pasarelasProducto)"
                    :state="aceptadoMoneda"
                  >
                    <!-- @change="modalTablaTarifaElimina=true" -->
                    <span slot="no-options">No hay opciones disponibles</span>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- CREDENCIALES STRIPE -->
            <div v-if="existeCredencial(2)">
              <label id="label-pasarela-visible">Credenciales de Stripe</label>
              <b-row>
                <b-col cols="12" md="6" lg="6" class="mx-0">
                  <b-form-group
                    label="Clave Pública"
                    label-for="clavePublicaStripe"
                  >
                    <b-form-input
                      id="clavePublicaStripe"
                      v-model="clavePublicaStripe"
                      :state="aceptadoClavePublicaStripe"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="6" class="mx-0">
                  <b-form-group
                    label="Clave Privada"
                    label-for="clavePrivadaStripe"
                  >
                    <b-form-input
                      id="clavePrivadaStripe"
                      v-model="clavePrivadaStripe"
                      :state="aceptadoClavePrivadaStripe"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <!-- CREDENCIALES KUSHKI -->
            <div v-if="existeCredencial(1)">
              <label id="label-pasarela-visible">Credenciales de Kushki</label>
              <b-row>
                <b-col cols="12" md="6" lg="6" class="mx-0">
                  <b-form-group
                    label="Clave Pública"
                    label-for="clavePublicaKushki"
                  >
                    <b-form-input
                      id="clavePublicaKushki"
                      v-model="clavePublicaKushki"
                      :state="aceptadoClavePublicaKushki"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="6" class="mx-0">
                  <b-form-group
                    label="Clave Privada"
                    label-for="clavePrivadaKushki"
                  >
                    <b-form-input
                      id="clavePrivadaKushki"
                      v-model="clavePrivadaKushki"
                      :state="aceptadoClavePrivadaKushki"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="mt-2">
              <label class="label-subtitles"> Términos y Condiciones *</label>
            </div>
            <!-- Field: terminos -->
            <!-- <b-col cols="12" md="6" lg="6"> -->
            <div>
              <b-form-textarea
                id="termsCondiciones"
                v-model="terminosCondiciones"
                rows="6"
                size="lg"
                :state="aceptadoTermsCond"
              ></b-form-textarea>
            </div>
          </b-form>

          <div class="container p-0 mt-1">
            <div class="row align-items-center">
              <div class="col">
                <label id="label-tarifa"> Tarifas (opc)</label>
                <div
                  class="row align-items-center"
                  style="margin-left: 0px; margin-bottom: 0; padding: 0"
                >
                  <div v-if="!aceptadoTarifas">
                    <feather-icon
                      icon="AlertCircleIcon"
                      size="16"
                      class="m-0 p-0"
                      style="color: red"
                    />
                    <label style="color: red !important; margin-left: 8px"
                      >Campo obligatorio</label
                    >
                  </div>
                </div>
              </div>
              <div class="col justify-content-end">
                <div style="display: flex">
                  <div style="margin-left: auto">
                    <b-button
                      @click="modalShow = !modalShow"
                      v-b-modal.modal-agregar
                      variant="outline-primary"
                      class="shadow-none"
                      :disabled="monedaBase.length === 0 || tableData.length == 1"
                      >Agregar otro precio</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Tabla -->
          <b-table
            :items="tableData"
            responsive
            :fields="fields"
            class="mb-0 text-center mt-1"
          >
            <!-- fecha -->
            <template #cell(code)="data">
              <div class="d-flex flex-column data-items-size">
                <span>{{ data.item.code }}</span>
              </div>
            </template>

            <!-- fecha -->
            <template #cell(nombre)="data">
              <div class="d-flex flex-column data-items-size">
                <span>{{ data.item.nombre }}</span>
              </div>
            </template>

            <!-- precio -->
            <template #cell(precio)="data">
              <div class="align-items-center">
                <div>
                  <div
                    class="font-weight-bolder text-uppercase data-items-size"
                  >
                    {{
                      '1 ' +
                        data.item.code +
                        ' = ' +
                        data.item.precio.toFixed(2) +
                        ' ' +
                        monedaBase.code
                    }}
                  </div>
                </div>
              </div>
              <!-- <feather-icon
                    icon="ChevronsLeftIcon"
                    size="18"
                    class="text-body align-middle"
                  /><feather-icon
                    icon="ChevronsRightIcon"
                    size="18"
                    class="text-body align-middle"
                  /> -->
            </template>

            <!-- id -->
            <!-- <template #cell(id)="data">
              <div class="align-items-center data-items-size">
                <span>{{ data.item.id }}</span>
              </div>
            </template> -->

            <!-- accion -->
            <template #cell(accion)="data">
              <div class="align-items-center">
                <button
                  class="action-button pr-2"
                  @click="editar(data.item)"
                  v-b-modal.modal-editar
                >
                  <feather-icon
                    icon="Edit2Icon"
                    size="16"
                    class="text-body align-middle"
                  />
                </button>
                <button
                  class="action-button pr-2"
                  @click="areyousure(data.item)"
                  v-b-modal.modal-eliminar-tarifa
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="18"
                    class="text-body align-middle"
                  />
                </button>
              </div>
            </template>
          </b-table>

          <!-- botones -->
          <div class="row align-items-center mt-5">
            <div>
              <b-alert
                v-height-fade.appear
                :show="dismissCountDown"
                dismissible
                class="mb-0 ml-1"
                variant="warning"
                id="empty-field-popup"
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChanged"
              >
                <div class="alert-body">
                  <span class="bold">Advertencia! </span>
                  <span>Debes completar los campos obligatorios </span>
                </div>
              </b-alert>
            </div>
            <div class="col justify-content-end">
              <div style="display: flex">
                <div style="margin-left: auto">
                  <b-button
                    id="cancel-button"
                    variant="outline-primary"
                    class="mr-1"
                    @click="modalCancelarShow = !modalCancelarShow"
                    v-b-modal.modal-Cancelar
                  >
                    Cancelar
                  </b-button>
                  <b-button
                    variant="primary"
                    class="shadow-none"
                    id="button-guardar"
                    @click="validateEmptyFields()"
                    :disabled="$store.state.appConfig.loading"
                  >
                    Guardar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-col>

    <!-- AGREGAR MONEDA -->
    <b-modal
      id="modal-agregar"
      title="Agregar moneda"
      ok-title="Agregar"
      ok-variant="primary shadow-none modal-button-size"
      cancel-title="Cancelar"
      cancel-variant="outline-primary shadow-none modal-button-size"
      hide-footer
      v-model="modalShow"
      centered
      class="w-50"
      ref="modal-agregar"
    >
      <b-form>
        <b-form-group>
          <b-row>
            <b-col>
              <b-form-group
                label="Codigo *"
                label-for="select-codigo"
                label-class="font-weight-bolder label-size"
              >
                <v-select
                  id="selectMonedaName"
                  v-model="currency.monedaCodigo"
                  :dir="dir"
                  label="code"
                  @input="selectMonedaName()"
                  :options="monedasModal"
                  :reduce="(moneda) => moneda.code"
                >
                  <span slot="no-options">No hay opciones disponibles</span>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group
          label="Nombre"
          label-for="precio"
          label-class="font-weight-bolder label-size"
        >
          <v-select
            v-if="currency.monedaCodigo != ''"
            id="selectMonedaName"
            :dir="dir"
            :options="monedasModal"
            label="name"
            v-model="currency.monedaName"
            @input="selectMonedaCode()"
            :reduce="(moneda) => moneda.name"
            class="mb-2"
          >
            <span slot="no-options">No hay opciones disponibles</span>
          </v-select>
        </b-form-group>

        <b-form-group>
          <b-row>
            <b-col class="col-md-6 col-12">
              <b-form-group
                :label="'Unidad en ' + monedaBase.name + '*'"
                label-for="precio"
                label-class="font-weight-bolder label-size"
              >
                <b-form-input
                  type="number"
                  id="selectMonedaName"
                  v-model="currency.unidadMonedaBase"
                  :dir="dir"
                  label="title"
                />
              </b-form-group>
            </b-col>
            <b-col class="col-md-6 col-12">
              <b-form-group
                label="Unidades equivalentes *"
                label-for="select-moneda"
                label-class="font-weight-bolder label-size"
                class="line"
              >
                <!-- :options="optionSimbolMonedas" -->
                <b-form-input
                  type="number"
                  id="select-moneda"
                  :dir="dir"
                  title="Simbolo"
                  v-model="currency.unidadMonedaExtra"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <div class="my-1">
          <b-alert
            v-height-fade.appear
            :show="dismissCountDownAddTarifa"
            dismissible
            variant="warning"
            id="empty-field-add-tarifa-popup"
            @dismissed="dismissCountDownAddTarifa = 0"
            @dismiss-count-down="countDownChangedAddTarifa"
          >
            <div class="alert-body">
              <span class="bold">Advertencia! </span>
              <span>Debes completar los campos obligatorios </span>
            </div>
          </b-alert>
        </div>

        <b-row align-h="between">
          <b-col cols="6">
            <b-button
              variant="outline-primary"
              class="mr-1 modal-button-size-tarifa-modal shadow-none"
              @click="hideAgregarModal"
            >
              Cancelar
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button
              variant="primary"
              class="modal-button-size-tarifa-modal shadow-none"
              @click="addMoneda"
            >
              Agregar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- <b-modal
      id="modal-agregar"
      title="Agregar otro precio"
      ok-title="Agregar"
      ok-variant="primary shadow-none modal-button-size"
      cancel-title="Cancelar"
      cancel-variant="outline-primary shadow-none modal-button-size"
      hide-footer
      v-model="modalShow"
      centered
      class="w-50"
      ref="modal-agregar"
    >
      <b-form>
        <b-form-group>
          <b-row>
            <b-col>
              <b-form-group
                label="Precio"
                label-for="precio"
                label-class="font-weight-bolder label-size"
              >
                <b-form-input id="precio" />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Moneda"
                label-for="select-moneda"
                label-class="font-weight-bolder label-size"
                class="line"
              >
                <v-select
                  id="select-moneda"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="['CLP', 'ARS', 'USD']"
                  title="Moneda"
                >
                  <span slot="no-options">No hay opciones disponibles</span>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <div class="row">
            <div class="form-check col-6">
              <b-form-radio
                name="some-radios"
                value="PagoUnico"
                class="custom-control-info"
              >
                Pago Único
              </b-form-radio>
            </div>
            <div class="form-check col-6">
              <b-form-radio
                name="some-radios"
                value="PagoRecurrente"
                class="custom-control-info"
              >
                Pago Recurrente
              </b-form-radio>
            </div>
          </div>
        </b-form-group>

        <b-form-group
          label="Pasarelas"
          label-for="select-pasarelas"
          label-class="font-weight-bolder label-size"
        >
          <v-select
            id="selectProductos"
            label="title"
            value="value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionPasarelas"
            title="Pasarelas"
            multiple
            class="mb-2"
          >
            <span slot="no-options">No hay opciones disponibles</span>
          </v-select>
        </b-form-group>

        <b-form-group
          label="Período de Pago"
          label-for="select-pasarelas"
          label-class="font-weight-bolder label-size"
        >
          <v-select
            class="mb-3"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="['Cada quincena', 'Cada mes', 'Cada año']"
            title="Período de Pago"
          >
            <span slot="no-options">No hay opciones disponibles</span>
          </v-select>
        </b-form-group>

        <b-row>
          <b-col>
            <b-button
              variant="outline-primary"
              class="mr-1 modal-button-size shadow-none"
              @click="hideAgregarModal"
            >
              Cancelar
            </b-button>
            <b-button
              variant="primary"
              class="modal-button-size shadow-none"
              id="agregar-button-modal"
              @click="hideAgregarModal"
            >
              Agregar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal> 
    -->

    <!-- modal editar tarifa -->
    <b-modal
      id="modal-editar"
      title="Modificar precio"
      ok-title="Modificar"
      ok-variant="primary shadow-none modal-button-size"
      cancel-title="Cancelar"
      cancel-variant="outline-primary shadow-none modal-button-size"
      hide-footer
      v-model="modalEditarShow"
      centered
      class="w-50"
      ref="modal-editar"
    >
      <b-form>
        <b-form-group>
          <b-row>
            <b-col>
              <b-form-group
                label="Codigo *"
                label-for="select-codigo"
                label-class="font-weight-bolder label-size"
              >
                <v-select
                  id="selectMonedaName"
                  v-model="currency.monedaCodigo"
                  :dir="dir"
                  label="code"
                  :reduce="(moneda) => moneda.code"
                  @input="selectMonedaName()"
                  :options="monedasModal"
                >
                  <span slot="no-options">No hay opciones disponibles</span>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group
          label="Nombre"
          label-for="precio"
          label-class="font-weight-bolder label-size"
        >
          <v-select
            v-if="currency.monedaCodigo != ''"
            id="selectMonedaName"
            :dir="dir"
            @input="selectMonedaCode()"
            :options="monedasModal"
            label="name"
            v-model="currency.monedaName"
            class="mb-2"
            :reduce="(moneda) => moneda.name"
          >
            <span slot="no-options">No hay opciones disponibles</span>
          </v-select>
        </b-form-group>

        <b-form-group>
          <b-row>
            <b-col>
              <b-form-group
                :label="'Unidad en ' + monedaBase.name + '*'"
                label-for="precio"
                label-class="font-weight-bolder label-size"
              >
                <b-form-input
                  type="number"
                  id="selectMonedaName"
                  v-model="currency.unidadMonedaBase"
                  :dir="dir"
                  label="title"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Unidades equivalentes*"
                label-for="select-moneda"
                label-class="font-weight-bolder label-size"
                class="line"
              >
                <!-- :options="optionSimbolMonedas" -->
                <b-form-input
                  type="number"
                  id="select-moneda"
                  :dir="dir"
                  title="Simbolo"
                  v-model="currency.unidadMonedaExtra"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <div class="my-1">
          <!-- v-height-fade.appear -->
          <b-alert
            :show="dismissCountDownEditTarifa"
            dismissible
            variant="warning"
            id="empty-field-add-tarifa-popup"
            @dismissed="dismissCountDownEditTarifa = 0"
            @dismiss-count-down="countDownChangedEditTarifa"
          >
            <div class="alert-body">
              <span class="bold">Advertencia! </span>
              <span>Todos los campos obligatorios </span>
            </div>
          </b-alert>
        </div>

        <b-row align-h="between">
          <b-col cols="6">
            <b-button
              variant="outline-primary"
              class="mr-1 modal-button-size-tarifa-modal shadow-none"
              @click="hideEditarModal"
            >
              Cancelar
            </b-button>
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              class="modal-button-size-tarifa-modal shadow-none"
              @click="guardarCambiosMoneda"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!-- <b-form>
        <b-form-group>
          <b-row>
            <b-col>
              <b-form-group
                label="Precio"
                label-for="precio"
                label-class="font-weight-bolder label-size"
              >
                <b-form-input id="precio" v-model="tarifaDataInfo.precio" />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Moneda"
                label-for="select-moneda"
                label-class="font-weight-bolder label-size"
                class="line"
              >
                <v-select
                  id="select-moneda"
                  v-model="tarifaDataInfo.moneda"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="['CLP', 'ARS', 'USD']"
                  title="Moneda"
                >
                  <span slot="no-options">No hay opciones disponibles</span>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <div class="row">
            <div class="col">
              <b-form-radio
                v-model="tarifaDataInfo.tipoPago"
                name="some-radios"
                value="PagoUnico"
                class="custom-control-info"
              >
                Pago Único
              </b-form-radio>
            </div>
            <div class="col">
              <b-form-radio
                v-model="tarifaDataInfo.tipoPago"
                name="some-radios"
                value="PagoRecurrente"
                class="custom-control-info radio-recurrente"
              >
                Pago Recurrente
              </b-form-radio>
            </div>
          </div>
        </b-form-group>

        <b-form-group
          label="Pasarelas"
          label-for="select-pasarelas"
          label-class="font-weight-bolder label-size"
        >
          <v-select
            id="selectProductos"
            v-model="tarifaDataInfo.pasarelas"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="['Kushki', 'Stripe', 'PEP']"
            title="Pasarelas"
            multiple
            class="mb-2"
          >
            <span slot="no-options">No hay opciones disponibles</span>
          </v-select>
        </b-form-group>

        <b-form-group
          label="Período de Pago"
          label-for="select-pasarelas"
          label-class="font-weight-bolder label-size"
        >
          <v-select
            class="mb-3"
            v-model="tarifaDataInfo.periodo"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="['Cada quincena', 'Cada mes', 'Cada año']"
            title="Período de Pago"
          >
            <span slot="no-options">No hay opciones disponibles</span>
          </v-select>
        </b-form-group>

        <b-row>
          <b-col>
            <b-button
              variant="outline-primary"
              class="mr-1 modal-button-size shadow-none"
              @click="hideEditarModal"
            >
              Cancelar
            </b-button>

            <b-button
              variant="primary"
              class="modal-button-size shadow-none"
              id="agregar-button-modal"
              @click="hideEditarModal"
            >
              Modificar
            </b-button>
          </b-col>
        </b-row>
      </b-form> -->
    </b-modal>

    <!-- modal eliminar -->
    <b-modal
      id="modal-eliminar-tarifa"
      body-bg-variant="light-warning"
      v-model="modalEliminartarifaShow"
      hide-footer
      hide-header
      centered
      size="sm"
      ref="modal-eliminar-tarifa"
    >
      <label id="label-confirmacion">
        ¿ Seguro que quieres eliminar esta tarifa?
      </label>
      <b-row>
        <b-col>
          <b-button
            variant="warning"
            class="mr-1 modal-button-size shadow-none"
            @click="hideEliminarModal"
          >
            Cancelar
          </b-button>
          <b-button
            variant="outline-warning"
            class="modal-button-size shadow-none"
            id="agregar-button-modal"
            @click="deleteCurrency()"
          >
            Eliminar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- modal cancelar -->
    <b-modal
      id="modal-Cancelar"
      body-bg-variant="light-warning"
      v-model="modalCancelarShow"
      hide-footer
      hide-header
      centered
      size="sm"
      ref="modal-Cancelar"
    >
      <label id="label-confirmacion-cancelar">
        ¿Seguro que quieres cancelar?
      </label>
      <b-row>
        <b-col>
          <b-button
            variant="warning"
            class="mr-1 modal-button-size shadow-none"
            @click="hideCancelarModal"
          >
            No
          </b-button>
          <router-link to="/dashboard/productos">
            <b-button
              variant="outline-warning"
              class="modal-button-size shadow-none"
              id="agregar-button-modal"
            >
              Sí
            </b-button>
          </router-link>
        </b-col>
      </b-row>
    </b-modal>

    <!-- modal Eliminar tabla de tarifas -->
    <b-modal
      id="modal-Tabla-Elimina"
      body-bg-variant="light-warning"
      v-model="modalTablaTarifaElimina"
      hide-footer
      hide-header
      centered
      class="xl"
    >
      <!-- ref="modal-Cancelar" -->
      <label id="label-confirmacion-Eliminar">
        ¿ Seguro que quieres proceder, se ELIMINARAN todos los datos de la tabla
        de tarifas?
      </label>
      <b-row>
        <b-col class="text-center">
          <b-button
            variant="warning"
            class="mr-1 modal-button-size shadow-none"
            @click="cancelareliminarTarifas()"
          >
            No
          </b-button>
          <b-button
            variant="outline-warning"
            class="modal-button-size shadow-none"
            id="modalTablaTarifaElimina"
            @click="eliminarTarifas(monedaBase)"
          >
            Sí
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BCard,
  BTable,
  BFormDatepicker,
  BModal,
  BFormRadio,
  BAlert,
  BFormTextarea,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onBeforeMount, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import moment from 'moment';
import Button from '@/views/components/button/Button.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { mounted } from 'vue-echarts';
import axios from 'axios';
import { backendUrl } from '@/config';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    BTable,
    Button,
    BFormDatepicker,
    BModal,
    BFormRadio,
    ToastificationContent,
    BAlert,
    BFormTextarea,
  },

  props: {
    productSelected: { type: Object, required: true },
  },

  /* created() {
    this.getPreviousData();
  }, */
  mounted() {
    this.getPreviousData();
  },
  methods: {
    existeCredencial(idCred) {
      let result;
      if (idCred == 1) {
        result = this.pasarelasProducto.map((x) => x.value).indexOf(1) > -1;
      } else if (idCred == 2) {
        result = this.pasarelasProducto.map((x) => x.value).indexOf(2) > -1;
      }
      return result;
    },
    selectMonedaName() {
      let array = this.monedasModal.filter(
        (elem) => elem.code == this.currency.monedaCodigo
      );
      this.currency.monedaName = array[0].name;
    },
    selectMonedaCode() {
      let array = this.monedasModal.filter(
        (elem) => elem.name == this.currency.monedaName
      );
      this.currency.monedaCodigo = array[0].code;
    },
    cambioMonedaBase(monedaBase) {
      this.modalTablaTarifaElimina = true;
    },
    eliminarTarifas(monedaBase) {
      this.monedaPrevia = this.monedaBase;
      this.modalTablaTarifaElimina = false;
      this.cambiar(monedaBase);
      this.tableData = Object.assign([]);
    },
    cancelareliminarTarifas() {
      this.monedaBase = this.monedaPrevia;
      this.modalTablaTarifaElimina = false;
    },
    cambiar(moneda) {
      this.moneda = moneda;
      this.monedaBase = this.moneda;
      this.monedasModal = this.optionMonedas.filter(
        (x) => x.id != this.monedaBase.id
      );
    },
    async getUtils() {
      const token = localStorage.getItem('token');
      var config = {
        method: 'get',
        url: `${backendUrl}/operaciones/util`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const resUtils = await axios(config);
        const monedas = this.$store.state.appConfig.currency;

        this.dataMonedas = monedas;

        this.optionMonedas = monedas  
        this.optionMonedasKushki = monedas.filter((x)=>x.kushkiEnable == 1)
        console.log('optionMonedasKushki', this.optionMonedasKushki)
        console.log('optionMonedas', this.optionMonedas)


      } catch (error) {
      }
    },
    addMoneda() {
      const paraTabla = {
        precio: '',
        code: '',
        nombre: '',
        simbolo: '',
        unidadMonedaBase: null,
        unidadMonedaExtra: null,
      };
      if (
        this.currency.unidadMonedaBase != '' &&
        this.currency.unidadMonedaExtra != '' &&
        this.currency.monedaCodigo != '' &&
        this.currency.monedaName != ''
      ) {
        paraTabla.precio =
          +this.currency.unidadMonedaBase / +this.currency.unidadMonedaExtra;
        paraTabla.code = this.currency.monedaCodigo;
        paraTabla.nombre = this.currency.monedaName;
        paraTabla.unidadMonedaBase = this.currency.unidadMonedaBase;
        paraTabla.unidadMonedaExtra = this.currency.unidadMonedaExtra;

        this.tableData.push(paraTabla);
        
        this.hideAgregarModal();

        this.currency.unidadMonedaExtra = '';
        this.currency.monedaCodigo = '';
        this.currency.monedaName = '';
        this.currency.unidadMonedaBase = '';
      } else {
        this.showAlertAddTarifa();
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    countDownChangedAddTarifa(dismissCountDown) {
      this.dismissCountDownAddTarifa = dismissCountDown;
    },
    showAlertAddTarifa() {
      this.dismissCountDownAddTarifa = this.dismissSecsAddTarifa;
    },
    countDownChangedEditTarifa(dismissCountDown) {
      this.dismissCountDownEditTarifa = dismissCountDown;
    },
    showAlertEditTarifa() {
      this.dismissCountDownEditTarifa = this.dismissSecsEditarTarifa;
    },
    hideEditarModal() {
      this.currency = {
        monedaName: '',
        monedaSymbol: '',
        monedaCodigo: '',
        unidadMonedaBase: '',
        unidadMonedaExtra: '',
      };
      this.$refs['modal-editar'].hide();
    },
    hideAgregarModal() {
      this.currency = {
        monedaName: '',
        monedaSymbol: '',
        monedaCodigo: '',
        unidadMonedaBase: '',
        unidadMonedaExtra: '',
      };
      this.$refs['modal-agregar'].hide();
    },
    hideEliminarModal() {
      this.$refs['modal-eliminar-tarifa'].hide();
    },
    areyousure(item) {

      this.modalEliminarTarifaShow = !this.modalEliminarTarifaShow;
      this.currencyDeleteElement = item;
    },
    deleteCurrency() {
      this.modalEliminarTarifaShow = !this.modalEliminarTarifaShow;
      
      this.tableData.splice(
        this.tableData.indexOf(this.currencyDeleteElement),
        1
      );
      this.hideEliminarModal();
    },
    hideCancelarModal() {
      this.$refs['modal-Cancelar'].hide();
    },
    editar(item) {
      this.modalEditarShow = !this.modalEditarShow;
      this.indexEdit = this.tableData.indexOf(item);
    
      this.currency = {
        monedaName: '',
        monedaSymbol: '',
        monedaCodigo: '',
        unidadMonedaBase: '',
        unidadMonedaExtra: '',
      };
      this.currency.monedaName = item.nombre;
      this.currency.monedaCodigo = item.code;
      this.currency.unidadMonedaBase = item.unidadMonedaBase;
      this.currency.unidadMonedaExtra = item.unidadMonedaExtra;
    },
    validarCampos() {
     
      if (
        this.currency.monedaName.length &&
        this.currency.monedaCodigo.length &&
        this.currency.unidadMonedaBase > 0 &&
        this.currency.unidadMonedaExtra > 0
      )
        return true;
      else return false;
    },
    guardarCambiosMoneda() {
      if (!this.validarCampos()) {
        this.showAlertEditTarifa();
        
        return;
      }
      if (this.indexEdit != -1) {
        var preTabla = {
          nombre: this.currency.monedaName,
          code: this.currency.monedaCodigo,
          precio:
            +this.currency.unidadMonedaBase / +this.currency.unidadMonedaExtra,
          unidadMonedaBase: this.currency.unidadMonedaBase,
          unidadMonedaExtra: this.currency.unidadMonedaExtra,
        };
        this.tableData.splice(this.indexEdit, 1);
        this.tableData.push(preTabla);
        this.hideEditarModal();
        this.currency = {
          monedaName: '',
          monedaSymbol: '',
          monedaCodigo: '',
          unidadMonedaBase: '',
          unidadMonedaExtra: '',
        };
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showProductoModificadoExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se ha modificado el Producto exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    errorDataToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Faltan datos a completar',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showProductoModificadoErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Modificar el Producto',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showProductoModificadoErrorNomToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'El nombre de producto ya existe',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showCredencialCreadaExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se han guardado las credenciales exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showCredencialCreadaErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Guardar credenciales',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    async getPreviousData() {
      // for (let k = 0; k < this.productSelected[0].credential.length; k++) {
      // let credentialStr = JSON.parse(
      //   this.productSelected[0].credential[k].credential
      // );
      // let creden = ;
      this.productSelected[0].credential.forEach(
        ({ credentials, id_gateway }) => {
          if (id_gateway == 2) {
            this.clavePrivadaStripe = JSON.parse(credentials).privateKey;
            this.clavePublicaStripe = JSON.parse(credentials).publicKey;
          } else if (id_gateway == 1) {
            this.clavePublicaKushki = JSON.parse(credentials).privateKey;
            this.clavePrivadaKushki = JSON.parse(credentials).publicKey;
          }
        }
      );
      // let obj = credentialStr,
      // }

      await this.getUtils();
      this.nombre = this.productSelected[0].name;
      this.codigoPage = this.productSelected[0].url_discount;
      this.tarifaDataInfo.moneda = this.productSelected[0].id_currency;
      this.terminosCondiciones = this.productSelected[0].Terms_and_Conditions;
      var monedatrae = this.optionMonedas.filter((x) => {
        if (x.id == this.productSelected[0].id_currency) return x;
      });
    
      this.monedaBase = monedatrae[0];
      this.pasarelasProducto = [];
      for (
        let index = 0;
        index < this.productSelected[0].credential.length;
        index++
      ) {
        if (this.productSelected[0].credential[index].id_gateway == 2) {
          this.pasarelasProducto.push({ title: 'Stripe', value: 2 });
        } else if (this.productSelected[0].credential[index].id_gateway == 1) {
          this.pasarelasProducto.push({ title: 'Kushki', value: 1 });
        }/*  else if (this.productSelected[0].credential[index].id_gateway == 3) {
          this.pasarelasProducto.push({
            title: 'Suscripciones Móviles',
            value: 3,
          });
        } */

        //Credentiales
        let auxCred;
        if (this.productSelected[0].credential[index].id_gateway == 2) {
          auxCred = JSON.parse(
            this.productSelected[0].credential[index].credentials
          );
          this.clavePublicaStripe = auxCred.publicKey;
          this.clavePrivadaStripe = auxCred.privateKey;
          auxCred = '';
        }
        if (this.productSelected[0].credential[index].id_gateway == 1) {
          auxCred = JSON.parse(
            this.productSelected[0].credential[index].credentials
          );
          this.clavePublicaKushki = auxCred.publicKey;
          this.clavePrivadaKushki = auxCred.privateKey;
        }
      }

      //Medios de Pago
      this.medios = [];
      if (this.productSelected[0].card_enable == 1) {
        this.medios.push('Tarjeta');
      }
      if (this.productSelected[0].cash_enable == 1) {
        this.medios.push('Efectivo');
      }
      if (this.productSelected[0].trasfer_enable == 1) {
        this.medios.push('Transferencia');
      }
      if (this.productSelected[0].entel_enable == 1) {
        this.medios.push('Entel');
      }
      if (this.productSelected[0].movistar_enable == 1) {
        this.medios.push('Movistar');
      }

      //Servicios
      this.servicios = [];
      if (this.productSelected[0].discount_enable == 1) {
        this.servicios.push('Códigos de Descuento');
      }
      if (this.productSelected[0].link_enable == 1) {
        this.servicios.push('Links de Pago');
      }
      if (this.productSelected[0].suscription_enable == 1) {
        this.servicios.push('Suscripciones');
      }

      //Tarifas
      this.tablaTarifas = [];
      this.tableData = [];
      for (
        let index = 0;
        index < this.productSelected[0].currency.length;
        index++
      ) {
        this.currency = {
          monedaName: '',
          monedaSymbol: '',
          monedaCodigo: '',
          unidadMonedaBase: '',
          unidadMonedaExtra: '',
        };
      
        let monedas = this.$store.state.appConfig.currency.filter((m)=> m.id == this.productSelected[0].currency[0].id_currency_ref)

          this.currency.monedaName = monedas[0].name;
          this.currency.monedaCodigo = monedas[0].code;
        
        this.currency.unidadMonedaBase = 1;
        this.currency.unidadMonedaExtra = this.productSelected[0].currency[
          index
        ].equivalent_amount;
        this.currency.id = this.productSelected[0].currency[index].id;

        this.tablaTarifas.push(this.currency);

        this.tableData.push({
          precio: this.currency.unidadMonedaExtra,
          code: this.currency.monedaCodigo,
          nombre: this.currency.monedaName,
          simbolo: this.currency.monedaSymbol,
          unidadMonedaBase: this.currency.unidadMonedaBase,
          unidadMonedaExtra: this.currency.unidadMonedaExtra,
          id: this.currency.id,
        });
        this.currency = {
          monedaName: '',
          monedaSymbol: '',
          monedaCodigo: '',
          unidadMonedaBase: '',
          unidadMonedaExtra: '',
        };
      }
      this.monedaPrevia = this.monedaBase;
      this.cambiar(this.monedaBase);
    },

    onChangePasarela() {
      this.medios = [];
    },

    async validateEmptyFields() {
      let result = true;
      result = result && this.nombre.length > 0;
      result = result && this.servicios.length > 0;
      result = result && this.medios.length > 0;
      result = result && this.pasarelasProducto.length > 0;
      result = result && this.monedaBase != null;
      result = result && this.terminosCondiciones.length > 0;
      result =
        result &&
        ((this.pasarelasProducto.map((x) => x.title).indexOf('Stripe') > -1 &&
          this.clavePrivadaStripe.length > 0 &&
          this.clavePublicaStripe.length > 0) ||
          this.pasarelasProducto.map((x) => x.title).indexOf('Stripe') == -1);
      result =
        result &&
        ((this.pasarelasProducto.map((y) => y.title).indexOf('Kushki') > -1 &&
          this.clavePublicaKushki.length > 0 &&
          this.clavePrivadaKushki.length > 0) ||
          this.pasarelasProducto.map((y) => y.title).indexOf('Kushki') == -1);

      if (result) {
        //this.loading = true;
        this.$store.commit('appConfig/changeLoading', true);
        const token = localStorage.getItem('token');
        var cardEnable = false;
        var cashEnable = false;
        var transferEnable = false;
        var movistarEnable = false;
        var entelEnable = false;
        if (this.medios.indexOf('Tarjeta') > -1) {
          cardEnable = true;
        }
        if (this.medios.indexOf('Efectivo') > -1) {
          cashEnable = true;
        }
        if (this.medios.indexOf('Transferencia') > -1) {
          transferEnable = true;
        }
        if (this.medios.indexOf('Entel') > -1) {
          entelEnable = true;
        }
        if (this.medios.indexOf('Movistar') > -1) {
          movistarEnable = true;
        }

        var suscriptionEnable = false;
        var linkEnable = false;
        var discountEnable = false;
        if (this.servicios.indexOf('Códigos de Descuento') > -1) {
          discountEnable = true;
        }
        if (this.servicios.indexOf('Links de Pago') > -1) {
          linkEnable = true;
        }
        if (this.servicios.indexOf('Suscripciones') > -1) {
          suscriptionEnable = true;
        }
        var id = this.$store.state.appConfig.projecto.id;
        var mon = this.monedaBase.id;

        let currency = [];
        console.log('thistable', this.tableData)
       
        for (let q = 0; q < this.tableData.length; q++) {
          let currency_ref = this.$store.state.appConfig.currency.find((m)=> m.code == this.tableData[0].code)
          
          console.log('codeee::', currency_ref)
          const data = {
            id_currency_ref: currency_ref.id,
            equivalent_amount: this.tableData[q].precio,
          };
          console.log('data:', data)
          currency.push(data);
        }

        //credenciales
        let credentials = [];

        if (this.pasarelasProducto.filter((p) => p.value == 2).length > 0) {
          let dataStripe = {
            id_gateway: 2,
            credentials: JSON.stringify({
              publicKey: this.clavePublicaStripe,
              privateKey: this.clavePrivadaStripe,
            }),
            showCredentials: 1,
          };

          credentials.push(dataStripe);
        }

        if (this.pasarelasProducto.filter((p) => p.value == 1).length > 0) {
          let dataKushki = {
            id_gateway: 1,
            credentials: JSON.stringify({
              publicKey: this.clavePublicaKushki,
              privateKey: this.clavePrivadaKushki,
            }),
            showCredentials: 1,
          };

          credentials.push(dataKushki);
        }
        if (this.pasarelasProducto.filter((p) => p.value == 3).length > 0) {
          let dataSuscripcionesMoviles = {
            id_gateway: 3,
            credentials: JSON.stringify({}),
            showCredentials: 1,
          };

          credentials.push(dataSuscripcionesMoviles);
        }

        var data = JSON.stringify({
          name: this.nombre,
          card_enable: cardEnable,
          trasfer_enable: transferEnable,
          cash_enable: cashEnable,
          movistar_enable: movistarEnable,
          entel_enable: entelEnable,
          suscription_enable: suscriptionEnable,
          link_enable: linkEnable,
          discount_enable: discountEnable,
          id_currency: mon,
          url_discount: this.codigoPage,
          Terms_and_Conditions: this.terminosCondiciones,
          currency: currency,
          credentials: credentials,
          //TODO código de descuento
        });
        

        var config = {
          method: 'put',
          url: `${backendUrl}/productos/edit/${id}`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data,
        };
        try {
          const respuesta = await axios(config);
          if(respuesta.data.code==-3){
            this.$store.commit('appConfig/changeLoading', false);
            this.showProductoModificadoErrorNomToast('danger');
            return;
          }
          console.log('resss', respuesta)

          let idEdit = respuesta.data.id;
          console.log('idEd', idEdit)
          const users = await axios({
            method: 'get',
            url: `${backendUrl}/usuarios/`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const user = users.data.user;

          const usuario = await user.filter(
            (e) => e.id === this.$store.state.appConfig.usuarioLogeado.id
          );
          await axios(config);
          const product = await axios({
            method: 'get',
            url: `${backendUrl}/productos/`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const productos = product.data.project;

          let productoEditado = productos.filter((e) => e.id == idEdit);
          this.$store.commit('appConfig/saveProyectosTotales', productos);
          console.log('sdsdsd', productoEditado)

          let productosStore = [];
          for (let f = 0; f < usuario[0].project.length; f++) {
            const resProj = await productos.filter(
              (e) => e.id === usuario[0].project[f].id_project
            );
            for (let k = 0; k < resProj.length; k++) {
              productosStore.push({
                id: resProj[k].id,
                name: resProj[k].name,
                currency: resProj[k].id_currency,
                terms: resProj[k].Terms_and_Conditions,
              });
            }
          }
          console.log('productoEditado', productoEditado)

          this.$store.commit('appConfig/changeProyectosStore', productosStore);
          this.$store.commit('appConfig/changeStore', {
            id: productoEditado[0].id,
            name: productoEditado[0].name,
            id_currency: productoEditado[0].id_currency,
            Terms_and_Conditions: productoEditado[0].Terms_and_Conditions,
          });
          this.$store.commit('appConfig/changeLoading', false);
          //this.loading = false;
          this.showProductoModificadoExitosoToast('success');
          this.$router.push('/dashboard/productos');
        } catch (error) {
          console.log('err', error)
          this.$store.commit('appConfig/changeLoading', false);
          this.showProductoModificadoErrorToast('danger'); 
        }
      } else {
       
        this.showAlert();
        this.nombre == ''
          ? (this.aceptadoNombre = false)
          : (this.aceptadoNombre = true);
        this.tarifaDataInfo.moneda == ''
          ? (this.aceptadoMoneda = false)
          : (this.aceptadoMoneda = true);
        /* this.codigo == ""
          ? (this.aceptadoDescuento = false)
          : (this.aceptadoDescuento = true); */
        // this.paises.length == 0
        //   ? (this.aceptadoPais = false)
        //   : (this.aceptadoPais = true);
        this.servicios.length == 0
          ? (this.aceptadoServicios = false)
          : (this.aceptadoServicios = true);
        this.medios.length == 0
          ? (this.aceptadoMedios = false)
          : (this.aceptadoMedios = true);
        this.pasarelasProducto.length == 0
          ? (this.aceptadoPasarela = false)
          : (this.aceptadoPasarela = true);
        this.terminosCondiciones == ''
          ? (this.aceptadoTermsCond = false)
          : (this.aceptadoTermsCond = true);
        /* this.tableData.length == 0
          ? (this.aceptadoTarifas = false)
          : (this.aceptadoTarifas = true); */
        this.pasarelasProducto.map((x) => x.title).indexOf('Stripe') > -1 &&
        this.clavePublicaStripe != ''
          ? (this.aceptadoClavePublicaStripe = true)
          : (this.aceptadoClavePublicaStripe = false);
        this.pasarelasProducto.map((x) => x.title).indexOf('Stripe') > -1 &&
        this.clavePrivadaStripe != ''
          ? (this.aceptadoClavePrivadaStripe = true)
          : (this.aceptadoClavePrivadaStripe = false);
        this.pasarelasProducto.map((x) => x.title).indexOf('Kushki') > -1 &&
        this.clavePrivadaKushki != ''
          ? (this.aceptadoClavePrivadaKushki = true)
          : (this.aceptadoClavePrivadaKushki = false);
        this.pasarelasProducto.map((x) => x.title).indexOf('Kushki') > -1 &&
        this.clavePublicaKushki != ''
          ? (this.aceptadoClavePublicaKushki = true)
          : (this.aceptadoClavePublicaKushki = false);
      }
    },
    getMediosPago(pasarelas) {
      let result = [];
      let aux = [];

      for (let index = 0; index < pasarelas.length; index++) {
        const element = pasarelas[index].title;
        if (element == 'Stripe') result.push(...this.optionMediosStripe);
        if (element == 'Kushki') result.push(...this.optionMediosKushki);
        if (element == 'Suscripciones Móviles')
          result.push(...this.optionMediosSusM);
      }


      aux = result.filter(function(item, pos, self) {
        return self.indexOf(item) == pos;
      });


      return aux;
    },
    getMonedas(pasarelas){
      console.log('pasare:::', pasarelas)
      let result = [];
      let aux = [];
      for (let index = 0; index < pasarelas.length; index++) {
        const element = pasarelas[index];
        console.log('eleme', element)
        if (element.title == 'Stripe') result.push(...this.optionMonedas);
        if (element.title  == 'Kushki') result.push(...this.optionMonedasKushki);
        if (element.title  == 'Suscripciones Móviles')
          result.push(...this.optionMonedasKushki.filter((m)=> m.code == 'CLP'));
      }
      console.log('resu:', result)
      aux = result.filter(function(item, pos, self) {
        return self.indexOf(item) == pos;
      });
        console.log('aux::', aux)

      return aux;
    },

    async saveCredentials(id_gateway, clavePublica, clavePrivada) {
      const token = localStorage.getItem('token');

      var data = JSON.stringify({
        id: id_gateway,
        keyPublic: clavePublica,
        keyPrivate: clavePrivada,
      });

      var config = {
        method: 'post',
        url: `${backendUrl}/productos/editCredentialsPP/`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };

      try {
        const resp = await axios(config);
        this.showCredencialCreadaExitosoToast('success');
      } catch (error) {
        this.showCredencialCreadaErrorToast('danger');
      }
    },
  },
  data() {
    return {
      optionMonedasKushki:[],
      monedaPrevia: {},
      tableData: [],
      modalShow: false,
      alertaModificar: false,
      modalEditarShow: false,
      modalEliminartarifaShow: false,
      modalCancelarShow: false,
      modalTablaTarifaElimina: false,
      dismissSecs: 5,
      codigoPage:'',
      dismissCountDown: 0,
      dismissSecsAddTarifa: 5,
      dismissSecsEditarTarifa: 5,
      dismissCountDownEditTarifa: 0,
      dismissCountDownAddTarifa: 0,
      dismissCountDownEditarTarifa: 0,
      optionMonedas:[],
      dir: 'ltr',
      monedaBase: -1,
      optionMedios: ['Efectivo', 'Tarjeta', 'Transferencia'],
      optionServicios: [
        'Suscripciones',
        'Links de Pago',
        'Códigos de Descuento',
      ],
      currencyDeleteElement: {},
      indexEdit: -1,
      

      fields: [
        { key: 'code', label: 'CODIGO' },
        { key: 'nombre', label: 'NOMBRE' },
        { key: 'precio', label: 'TASA' },
        { key: 'accion', label: 'ACCION' },
      ],
      terminosCondiciones: '',
      monedaBase: null,
      optionPasarelas: [
        {
          title: 'Kushki',
          value: 1,
        },
        { title: 'Stripe', value: 2 },
        // { title: 'Suscripciones Móviles', value: 3 },
      ],
      //DATOS PRODUCTO EDITADO
      nombre: '',
      medios: [],
      servicios: [],
      codigo: '',
      // paises: [],
      moneda: [],
      pasarelasProducto: [],
      termsConditions: '',
      //TARIFAS
      currency: {
        monedaName: '',
        monedaSymbol: '',
        monedaCodigo: '',
        unidadMonedaBase: '',
        unidadMonedaExtra: '',
      },
      //
      optionMediosKushki: ['Tarjeta', 'Transferencia', 'Efectivo'],
      optionMediosStripe: ['Tarjeta'],
      optionMediosSusM: ['Entel', 'Movistar'],
      //

      tablaTarifas: [],
      //CAMPOS OBLIGATORIOS
      aceptadoNombre: true,
      aceptadoPasarela: true,
      aceptadoMedios: true,
      aceptadoServicios: true,
      aceptadoNombre: true,
      // aceptadoPais: null,
      aceptadoMoneda: true,
      aceptadoTermsCond: true,
      aceptadoTarifas: true,
      aceptadoDescuento: true,
      aceptadoClavePublicaStripe: null,
      aceptadoClavePrivadaStripe: null,
      aceptadoClavePublicaKushki: null,
      aceptadoClavePrivadaKushki: null,
      //CREDENCIALES
      clavePrivadaStripe: '',
      clavePublicaStripe: '',
      clavePublicaKushki: '',
      clavePrivadaKushki: '',
    };
  },

  setup() {
    const userDataInfo = ref({
      dob: null,
      nombre: '',
      medios: [],
      servicios: [],
      codigo: '',
      paises: [],
    });

    const tarifaDataInfo = ref({
      precio: 'CLP 100.00',
      tipoPago: '',
      pasarelas: [],
      periodo: '',
      moneda: '',
    });

    const pagoOptions = [
      { text: 'Pago Único', value: 'Pago Único' },
      { text: 'Pago Recurrente', value: 'Pago Recurrente' },
    ];

    return {
      userDataInfo,
      pagoOptions,
      tarifaDataInfo,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.selectPasarelas .vs__selected-options .vs__selected,
.selectProductos .vs__selected-options .vs__selected,
.selectMediosPago .vs__selected-options .vs__selected,
.selectServicios .vs__selected-options .vs__selected,
.selectPais .vs__selected-options .vs__selected {
  background: $info;
  border-radius: 20px;
}

.action-button {
  border: none;
  background: transparent;
  width: 18px;
}

#cancel-button {
  width: 146.22px;
}

#button-guardar {
  width: 146.22px;
}

.data-items-size {
  font-size: 12px;
}

::v-deep .table th {
  text-align: center !important;
}

#label-tarifa {
  font-size: 15px;
  font-weight: bold;
}

.modal-button-size {
  width: 166px;
}

.modal-button-size-tarifa-modal {
  width: 100%;
}

#agregar-button-modal {
  margin-left: 14px;
}
#modal-Tabla-Elimina {
  display: flex;
  align-content: center;
  justify-content: center;
}
#label-confirmacion {
  margin-left: 12%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}

#label-confirmacion-cancelar {
  margin-left: 22%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}
#label-confirmacion-Eliminar {
  margin-left: auto;
  text-align: center;
  margin-bottom: auto;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}
.bold {
  font-weight: bold;
}

#label-pasarela-visible {
  font-size: 13px;
  font-weight: bolder;
  margin: 4px 0 4px 0;
}
</style>
